
import { defineComponent, createVNode } from "vue";
import {
  Col,
  Row,
  Input,
  Button,
  Cascader,
  Table,
  Pagination,
  Popover,
  message,
  Image,
} from "ant-design-vue";
import {
  UnorderedListOutlined,
  SettingOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileAddFilled,
  PoweroffOutlined,
  ReloadOutlined,
  ColumnHeightOutlined,
  TagsFilled,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FolderAddFilled, //文件夹
  FolderOpenFilled, //被打开的文件夹
  FolderFilled,
} from "@ant-design/icons-vue";
import { brandList, deleteBrand } from "@/api/terminalList.js";
import { brandColumns } from "@/api/fixedData.js";
import Modaladd from "@/components/brand/Modal.vue";
import EditModal from "@/components/brand/EditModal.vue";
import { Modal } from "ant-design-vue";
import ChildModal from "@/components/brand/ChildModal.vue";
export default defineComponent({
  components: {
    Col,
    Row,
    Input,
    Button,
    Cascader,
    UnorderedListOutlined,
    SettingOutlined,
    CloudUploadOutlined,
    CloudDownloadOutlined,
    FileAddFilled,
    PoweroffOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    TagsFilled,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    Table,
    Pagination,
    Popover,
    Modaladd,
    Image,
    ChildModal,
    EditModal,
    FolderAddFilled, //文件夹
    FolderOpenFilled, //被打开的文件夹
    FolderFilled,
  },
  data() {
    return {
      //table的数据
      data: [],
      //table的列名字
      columns: [],
      // 当前页数
      pageCurrent: 1,
      // 每页数据数量
      pageSize: 10,
      pageNum: 1,
      //总数据量
      total: 0,
      tableHeight: "400px",
      searchFlag: false,
      //table的布局
      scroll: { x: "100%" },
      //table的loading
      loading: true,
      //批量操作按钮加载
      batchLoading: false,
      //table的样式
      batchStyle: "middle",
    };
  },
  methods: {
    //更换每页显示数量
    onShowSizeChange(current: any, pageSize: any) {
      this.pageNum = 1;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      this.getBrandList();
    },
    showModal(recode) {
      (this as any).$refs.showModal.showModal(recode);
    },
    showEditModal(recode) {
      (this as any).$refs.showEditModal.showModal(recode);
    },
    showChildModal(recode) {
      (this as any).$refs.showChildModal.showModal(recode);
    },
    //删除品牌 单个
    handleDeleteBrand(recode) {
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: "是否确认删除该品牌？",
        cancelText: "取消",
        okText: "确认",
        onOk() {
          deleteBrand(recode.brandCode).then((res: any) => {
            //
            if (res.data.success) {
              that.getBrandList();
              message.success("删除成功");
            }
            // else {
            //   message.error(res.data.errMessage);
            // }
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      });
    },
    //获取品牌列表
    getBrandList() {
      this.loading = true;
      brandList(this.pageNum, this.pageSize)
        .then((res: any) => {
          let result = res.data;
          this.total = result.total;
          let level = 0;
          this.convertKey(result.data, level);
          this.data = result.data||[];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    convertKey(arr, level) {
      if (arr===undefined) {
        arr=[]
      }
      level++;
      arr.forEach((item) => {
        item.level = level;
        if (item.imgLogo) {
          if (!item.imgLogo.includes(".jp") && !item.imgLogo.includes(".png")) {
            item.imgLogo = "";
          }
        }
        if (item.imgLogo === null) {
          item.imgLogo = "";
        }
        if (item["childBrandList"].length != 0) {
          item.level = level;
          item["children"] = item["childBrandList"];
          (this as any).convertKey(item.childBrandList, level);
        }
      });
      return arr;
    },
    todoTeload() {
      this.getBrandList();
    },
    expandIcon(props) {
      console.log(props);
    },
    //跳转到导入页面
    batchImport() {
        this.$router.push("/masterData/brand/batchImport");
    },
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      //
      this.pageNum = this.pageCurrent;
      this.getBrandList();
    },
  },
  mounted() {
    // this.scroll.y = window.screen.height - 468;
    //获取品牌列表信息
    this.getBrandList();
    // 获取columns 拼接操作栏
    (this as any).columns = [
      ...brandColumns,
      {
        title: "操作",
        slots: { customRender: "handle" },
        fixed: "right",
        width: 200,
      },
    ];
  },
});
